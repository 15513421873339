<template>
        <div :class="bodyClass">
            <div class="row" v-if="targetProcessIdFieldName && targetProcessFieldName">
                <div class="col-6">
                    <label class="form-label">{{ $t("Target Process") }}</label>
                    <OScopeProcessesLookup class="form-control form-control-sm"
                        :bind="sel => { row[targetProcessIdFieldName!] = sel.ID, row[targetProcessFieldName!] = sel.Name }"
                        v-model="row[targetProcessFieldName!]"
                        :whereClause="`DescendantOrgUnit_ID = ${row.OrgUnit_ID}`" forceReloadOnOpen />
                </div>
            </div>
            <slot name="checkmarks">
            
            </slot>
            <div class="row" v-if="startDateFieldName">
                <div class="col-6">
                    <label class="form-label">{{ $t("{startDateFieldName}", { startDateFieldName: props.startDateFieldName.split(/(?=[A-Z])/).join(" ") }) }}</label>
                    <ODatePicker class="form-control form-control-sm" v-model="startDate" timepicker />
                </div>
            </div>
            <div class="row" v-if="!startDateFieldName">
                <div class="col-6">
                    <label class="form-label">{{ $t("Select Day") }}</label>
                    <ODatePicker class="form-control form-control-sm" v-model="startDate" format="dd.mm"/>
                </div>
            </div>
            <div class="row mt-2">
                <!--<div class="col-6">
                    <label for="inputEmail3" class="form-label">{{$t("Select Preset")}}</label>
                    <ODataLookup class="form-control form-control-sm"
                        :bind="sel => { model.RepeatFrequency = sel.Name, model.RepeatFrequency_ID = sel.ID, model.Frequency = sel.Formula }"
                        v-model="model.RepeatFrequency" :dataObject="dsFreq">
                        <OColumn field="ID" width="80" />
                        <OColumn field="Name" width="250" />
                    </ODataLookup>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <hr class="flex-grow-1">
                    <span class="ps-2 pe-2">
                        OR
                    </span>
                    <hr class="flex-grow-1">
                </div>-->
                <div>
                    <Frequency ref="freqControlRef"
                        :endDate="editorEndDate"
                        :startDate="editorStartDate"
                        :formula="formula"
                        @update:startTime="updStartTime"
                        @update:frequency="$event => handleFrequencyUpdate($event)" />
                </div>
                <div class="col-6" v-if="endDateFieldName">
                    <label class="form-label">{{ $t("{endDateFieldName}", { endDateFieldName: props.endDateFieldName.split(/(?=[A-Z])/).join(" ") }) }}</label>
                    <ODatePicker class="form-control form-control-sm" v-model="endDate" date
                        format="Short Date" />
                </div>
            </div>
        </div>  
        <div :class="[footerClass, autoGenerate !== undefined ? !autoGenerate ? 'justify-content-between' : 'justify-content-end' : 'justify-content-end']">
            <template v-if="autoGenerate !== undefined">
                <span class="text-muted" v-if="!autoGenerate">
                    <!-- {{ vNumberOfItems }} {{ $t("item(s) will be created") }} -->
                </span>
                <div :class="[`justify-content-${buttonRowAlignment}`, 'd-flex gap-2']"> 
                    <button class="btn btn-outline-primary btn-sm" @click="saveChanges(); emit('save')" data-bs-dismiss="modal" :disabled="isGeneratingConnectedItemsComp">
                        {{ $t("Save") }}
                    </button>
                    <button class="btn btn-outline-secondary btn-sm" @click="clearFrequency()" :disabled="isGeneratingConnectedItemsComp">
                        {{ $t("Clear Frequency") }}
                    </button>
                    <button class="btn btn-outline-primary btn-sm" :disabled="generateDisabled" @click="generateItems" :title="generateButtonTitle">
                        <div v-if="isGeneratingConnectedItemsComp" class="spinner-border spinner-border-sm" style="0.35rem;"
                            role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                        {{ generateButtonLabel || $t("Generate Items") }}
                    </button>        
                    <slot name="buttons" />
                </div>
            </template>  
            <template v-else>
                <button class="btn btn-outline-primary btn-sm "
                    data-bs-dismiss="modal">
                    {{ $t("Close") }}
                </button>
            </template>
        </div>
</template>

<script setup lang="ts">
import { ref, defineExpose, defineProps, defineEmits, computed } from 'vue';
import Frequency from './FrequencyEditor.Editor.vue';
import { OScopeProcessesLookup } from "o365-system-lookups";
import { DataItemModel } from 'o365-dataobject';

const props = withDefaults(defineProps<{
    row: DataItemModel,
    startDateFieldName: string,
    endDateFieldName: string,
    autoGenerate?: boolean,
    targetProcessFieldName?: string,
    targetProcessIdFieldName?: string,
    numberOfItems?: number,
    isGeneratingConnectedItems?: boolean,
    footerClass?: string,
    bodyClass?: string,
    buttonRowAlignment?: "start" | "between" | "end",
    generateButtonLabel?: string,
    generateButtonTitle?: string,
}>(), {
    autoGenerate: undefined,
    footerClass: "modal-footer d-flex",
    bodyClass: "modal-body bg-light",
    buttonRowAlignment: "between"
});

const freqControlRef = ref();
const startDate = ref(props.row[props.startDateFieldName] ? new Date(props.row[props.startDateFieldName]) : null);
const editorStartDate = computed(() => startDate.value ? new Date(startDate.value) : null)
const endDate = ref(props.row[props.endDateFieldName] ? new Date(props.row[props.endDateFieldName]) : null);
const editorEndDate = computed(() => endDate.value ? new Date(endDate.value) : null);
const repeatFrequency_ID = ref(props.row.RepeatFrequency_ID);
const repeatFrequency = ref(props.row.RepeatFrequency);
const formula = ref(props.row.RepeatFrequencyFormula);
const isGeneratingItems = ref(false);

const emit = defineEmits(['generateItems', 'save'])

const isGeneratingConnectedItemsComp = computed(() => props.isGeneratingConnectedItems || isGeneratingItems.value);
const generateDisabled = computed(() => {
    return !(formula.value && startDate.value && endDate.value) || props.autoGenerate || isGeneratingConnectedItemsComp.value;
});

function syncData() {
    if (!datesEqual(startDate.value, props.row[props.startDateFieldName])) {
        startDate.value = props.row[props.startDateFieldName];
    }

    if (!datesEqual(endDate.value, props.row[props.endDateFieldName])) {
        endDate.value = props.row[props.endDateFieldName];
    }
}

function generateItems() {
    isGeneratingItems.value = true;
    saveChanges().then(() => {
        emit('generateItems');
    }).finally(() => {
        isGeneratingItems.value = false;
    });
}

function updStartTime(newDate) {
    startDate.value = newDate;
}

function handleFrequencyUpdate(pValue) {
    if (repeatFrequency_ID.value !== null) {
        repeatFrequency_ID.value = null;
        repeatFrequency.value = null;
    }
    formula.value = pValue;
}
     
function clearFrequency() {
    freqControlRef.value?.resetToDefault();
    handleFrequencyUpdate(null);
}

function datesEqual(dateA, dateB) {
    const unifiedDateA = dateA instanceof Date ? dateA : new Date(dateA);
    const unifiedDateB = dateB instanceof Date ? dateB : new Date(dateB);
    return unifiedDateA.getTime() === unifiedDateB.getTime();
}

async function saveChanges() {
    return new Promise((resolve, reject) => {
        props.row[props.startDateFieldName] = startDate.value;
        props.row[props.endDateFieldName] = endDate.value;
        props.row.RepeatFrequency_ID = repeatFrequency_ID.value;  
        props.row.RepeatFrequency = repeatFrequency.value;
        props.row.RepeatFrequencyFormula = formula.value;
        props.row.save()
        .then(resolve)
        .catch(reject)
    });
}

defineExpose({ syncData });
</script>